/* eslint-disable */


export class UtilLocaleNumberParser {

  private _group: RegExp;
  private _decimal: RegExp;
  private _numeral: RegExp;
  private _index: (d: any) => number;
  private _locale: string;

  get locale(): string {
    return this._locale;
  }

  set locale(value: string) {
    this._locale = value;
    this.init();
  }

  constructor(locale: string) {
    this.locale = locale;
  }

  parse(string: any) {
    return (string = string.trim()
      .replace(this._group, '')
      .replace(this._decimal, '.')
      .replace(this._numeral, this._index)) ? +string : NaN;
  }

  private init() {
    const parts = new Intl.NumberFormat(this.locale).formatToParts(12345.6);
    const numerals = [...new Intl.NumberFormat(this.locale, {useGrouping: false}).format(9876543210)].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(`[${parts.find(d => d.type === 'group').value}]`, 'g');
    this._decimal = new RegExp(`[${parts.find(d => d.type === 'decimal').value}]`);
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g');
    this._index = d => index.get(d);
  }
}
